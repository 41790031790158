export const getDefaultNationalMetaData = _ref => {
  let {
    country,
    type,
    category,
    retailer
  } = _ref;
  switch (type) {
    case 'homepage':
      return {
        title: "Westfield ".concat(country, " - Visit Our Centres or Browse Online"),
        description: 'Westfield is your one-stop destination for shopping, leisure and entertainment. Discover 1000s of popular retailers in fashion, home, decor and more.'
      };
    case 'experience':
      return {
        title: "Westfield ".concat(country, " | ").concat(category),
        description: "Westfield is home to your favourite ".concat(category, " products. Explore our range of ").concat(category, " products, categories, brands, events & offers.")
      };
    case 'storefront':
      return {
        title: "Westfield ".concat(country, " | ").concat(retailer),
        description: "".concat(retailer, " at Westfield - Shop online and explore ").concat(retailer, "\u2019s range of products and categories. Find 100s of quality retailers at your local Westfield\n")
      };
    case 'gift-cards':
      return {
        title: "Westfield Gift Cards - Check Card Balance, Buy Gift Cards",
        description: "Westfield Gift Cards opens doors to 8000+ shopping experiences in Westfield Centres across Australia. You can check card balances and buy Westfield gift cards online."
      };
    case 'privacy-policy':
      return {
        title: "National Website Privacy Policy | Westfield ".concat(country),
        description: "Your privacy is important to us and we are committed to the protection of personal information. Read more on Westfield Australia's website privacy policy here."
      };
    case 'terms-and-conditions':
      return {
        title: "National Website Terms & Conditions | Westfield ".concat(country),
        description: "We update our site as often as is practicable, but information can change often and we can't guarantee its accuracy at any time. Read more on Westfield Australia's website terms and conditions here."
      };
    case 'promotions-terms-and-conditions':
      return {
        title: "Promotions Terms & Conditions | Westfield ".concat(country),
        description: "We update our site as often as is practicable, but information can change often and we can't guarantee its accuracy at any time. Read more on Westfield Australia's website terms and conditions here."
      };
    case 'search':
      return {
        title: "Westfield ".concat(country, " - Search Results"),
        description: 'Westfield is your one-stop destination for shopping, leisure and entertainment. Discover 1000s of popular retailers in fashion, home, decor and more.'
      };
    case 'personalised-ads':
      return {
        title: "Personalised Ads",
        description: 'To show you the most relevant information and advertising, we collect details of your browsing session. If you would prefer to see general and potentially less relevant advertising, you can use the controls below.'
      };
    case 'shop':
      return {
        title: "Westfield online | Shop Westfield retailers online",
        description: 'Explore Westfield online, your ultimate shopping destination! Dive into a diverse range of products from Westfield retailers, all from the comfort of your home. Enjoy seamless online shopping experiences, offers and effortless delivery services. Start shopping today.'
      };
    case 'default':
    default:
      return {
        title: "Westfield ".concat(country, " - Visit Our Centres or Browse Online"),
        description: 'Westfield is your one-stop destination for shopping, leisure and entertainment. Discover 1000s of popular retailers in fashion, home, decor and more.'
      };
  }
};